import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  Props as ServiceProps,
  Service,
} from 'app/components/ServicesList/Service'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Icon } from './Icon'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  services: ServiceProps[]
  servicesTitle?: string
  title?: string
}

export const AdvancedServicesList = memo(function AdvancedServicesList({
  description,
  image,
  label,
  services,
  servicesTitle,
  title,
}: Props) {
  if (!services) {
    return null
  }

  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      <Head>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Head>

      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}

      <Wrapper>
        <Wave />

        {servicesTitle ? (
          <FadeInUp>
            <ServicesTitle>{servicesTitle}</ServicesTitle>
          </FadeInUp>
        ) : null}

        <Services dial={6} row space="between" wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </Wrapper>

      <Icon />
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding: 8.375rem 0 9.375rem;
  position: relative;

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const Head = styled.div`
  max-width: 52.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: auto;
  padding: 6.5rem 7.8125rem;
  position: relative;
  text-align: center;
  z-index: 3;

  @media (max-width: 1199px) {
    max-width: none;
    padding: 2.5rem 1.5rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const Background = styled.div`
  width: 100%;
  height: 55.6vh;
  max-height: 31.25rem;
  position: absolute;
  top: 22.125rem;
  left: 0;
  z-index: 2;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 20rem;
    position: relative;
    top: auto;
    left: auto;
    &:after {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  margin-top: 8.75rem;
  padding: 0 1.5rem;
  position: relative;
  z-index: 2;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    position: absolute;
    top: 3.125rem;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: 7.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin: auto;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-top: 0;
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
    text-align: left;
    &:before {
      display: none;
    }

    > svg {
      width: 5rem;
    }
  }
`

const ServicesTitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Services = styled(FlexBox)`
  max-width: 50rem;
  margin: 3.125rem auto 0;

  > div {
    width: calc(50% - 2.5rem);
    font-size: 0.9375rem;
    letter-spacing: 0.0938rem;
    line-height: 1.1875rem;
    margin: 2.125rem 0 0 2.5rem;
    text-transform: uppercase;
  }

  @media (max-width: 1199px) {
    max-width: none;
    margin-top: 1.875rem;

    > div {
      width: 100%;
      margin: 1.875rem 0 0;
    }
  }
`
