import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  title?: string
}

export const Item = memo(function Item({
  cta,
  description,
  images,
  title,
}: Props) {
  return (
    <Container row stretch wrap>
      <LeftSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA variant="simple" {...cta} />
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        <Wrapper row space="between">
          <Wave />

          {images
            ? images.map((item, index) => (
                <LazyLoadComponent key={index}>
                  <Image {...item} />
                </LazyLoadComponent>
              ))
            : null}
        </Wrapper>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9.375rem;
  &:first-of-type {
    margin-top: 10.9375rem;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        padding-right: 0;
        padding-left: 6.111vw;
        &:before,
        &:after {
          right: auto;
          left: 0;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    margin-top: 6.25rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:nth-of-type(even) {
      > div {
        &:last-of-type {
          padding-left: 0;
        }
      }
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 37%;
  padding: 0 6.111vw;

  @media (max-width: 1199px) {
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    order: 2;
    padding: 2.5rem 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const CTA = styled(Button)`
  margin-top: 2.625rem;
  &:before {
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    opacity: 1;
  }
`

const RightSide = styled.div`
  width: 63%;
  padding: 6.25rem 6.111vw 6.25rem 0;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 0;
  }
  &:before {
    width: 78%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    top: 0;
  }
  &:after {
    width: 32%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    bottom: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 0;
    &:before,
    &:after {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  height: 100%;
  z-index: 2;

  > svg {
    width: 100%;
    height: 4.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
  }

  picture {
    width: calc(50% - 0.75rem);
    height: 56.4vh;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    > svg {
      width: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    picture {
      width: calc(50% - 0.1875rem);
      height: 17.5rem;
    }
  }
`
